import { Form, Input, Select, Button, Row, Col, message, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { AddBankFormState } from '../../interfaces'
import { selectPostAddBankAccountLoading, postAddBankAccount, getBankAccounts, postValidateBankAccount } from '../../reducers'
import { BankAndWhiteListButtonsContainer } from '../../styles'
import { selectGlobalCurrency } from '../../../AuthLayout/reducers'
import { bankTypeList, bankTypes, getCurrencyValue } from '../../../../utils/utils'
import { useEffect, useState } from 'react'

const { useBreakpoint } = Grid

const AddBankForm = (props: AddBankFormState): JSX.Element => {
  const { setAddVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postAddBankAccountLoading = useAppSelector(selectPostAddBankAccountLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const [form] = Form.useForm()
  const [bankType, setBankType] = useState<(typeof bankTypes)[keyof typeof bankTypes]>(bankTypes.IB)

  const providerSelectList = selectList.providerSelectList.reduce((prev, curr) => {
    if (globalCurrency == null ||
      getCurrencyValue(globalCurrency) !== curr.currency ||
      bankType !== curr.type ||
      (bankType === bankTypes.EP && curr.code !== 'CC003')
    ) return prev
    const item = {
      label: `${curr.code} - ${curr.name}`,
      value: curr.code
    }
    return [...prev, item]
  }, [] as { label: string, value: string }[])

  useEffect(() => {
    if (providerSelectList.length <= 1) {
      form.setFieldsValue({ bankCode: providerSelectList[0]?.value })
    }
  }, [providerSelectList, form])

  const isEP = bankType === bankTypes.EP
  const bankNamePlaceholder = isEP ? 'Wallet Address' : 'Beneficiary Name'

  async function onHandleAddBankSubmit(values: any): Promise<any> {
    const addSubmitPayload = {
      bankCode: values.bankCode,
      branch: values.branch,
      name: values.name,
      ...isEP ? {} : { number: values.number, },
      merchantId
    }
    const filtersPayload = {
      ...filters
    }

    try {
      await dispatch(postAddBankAccount(addSubmitPayload)).then(unwrapResult)
      await dispatch(getBankAccounts(filtersPayload))
      await dispatch(postValidateBankAccount())
      await setAddVisible(false)
      return await message.success('Add Bank Account Success!')
    } catch (error: any) {
      await message.error(error.message)
      return form.resetFields()
    }
  }

  function onHandleCancel(): void {
    setAddVisible(false)
  }

  return (
    <Form
      name='add-bank-form'
      form={form}
      onFinish={onHandleAddBankSubmit}
      requiredMark={false}
      layout='vertical'
      initialValues={{
        bankType: bankTypes.IB,
        bankCode: undefined,
        branch: '',
        name: '',
        number: ''
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Bank Type'
            name='bankType'
          >
            <Select
              onChange={(value) => {setBankType(value) }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              showSearch placeholder='Select Bank' options={bankTypeList}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Bank'
            name='bankCode'
            rules={[{ required: true, message: 'Bank is required.' }]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              showSearch placeholder='Select Bank' options={providerSelectList}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Remarks'
            name='branch'
            rules={[{ required: true, message: 'Remarks is required.' }]}
          >
            <Input placeholder='Remarks' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label={bankNamePlaceholder}
            name='name'
            rules={[{ required: true, message: `${bankNamePlaceholder} is required.` }]}
          >
            <Input placeholder={bankNamePlaceholder} />
          </Form.Item>
        </Col>
        {!isEP &&
        <Col xs={24} sm={12}>
          <Form.Item
            label='Account Number'
            name='number'
            rules={[{ required: true, message: 'Account Number is required.' }, { pattern: new RegExp('^[0-9]*$'), message: 'field only accepts numbers.' }]}
          >
            <Input placeholder='Account Number' />
          </Form.Item>
        </Col>
        }
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postAddBankAccountLoading}
        >
          Submit
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default AddBankForm
