import { BankRowDetails, EditBankFormState } from '../../interfaces'
import { Form, Input, Select, Button, Row, Col, message, Grid } from 'antd'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { postUpdateBankAccount, getBankAccounts, selectPostUpdateBankAccountLoading, postValidateBankAccount } from '../../reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { BankAndWhiteListButtonsContainer } from '../../styles'
import { bankTypeList, bankTypes } from '../../../../utils/utils'

const { useBreakpoint } = Grid

const EditBankForm = (props: BankRowDetails & EditBankFormState): JSX.Element => {
  const { bankCode, accName, cardNumber, branch, status, id, setEditVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postUpdateBankAccountLoading = useAppSelector(selectPostUpdateBankAccountLoading)

  const bankSelected = selectList.providerSelectList.find(v => v.code === bankCode)
  const bankText = bankSelected ? `${bankSelected.code} - ${bankSelected.name}` : ''
  const bankType = bankSelected?.type

  const isEP = bankType === bankTypes.EP
  const bankNamePlaceholder = isEP ? 'Wallet Address' : 'Beneficiary Name'

  const mAccountStatusSelectList = selectList.mAccountStatusSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  function onHandleCancel(): void {
    setEditVisible(false)
  }

  async function onHandleEditBankSubmit(values: any): Promise<any> {
    const editSubmitPayload = {
      bankCode: values.bankCode,
      branch: values.branch,
      id,
      merchantId,
      name: values.accName,
      ...isEP ? {} : { number: values.cardNumber },

      status: values.status
    }
    const filtersPayload = {
      ...filters
    }

    try {
      await dispatch(postUpdateBankAccount(editSubmitPayload)).then(unwrapResult)
      await dispatch(postValidateBankAccount())
      await setEditVisible(false)
      dispatch(getBankAccounts(filtersPayload))
      return await message.success('Updated Bank Account Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  return (
    <Form
      name='edit-bank-form'
      onFinish={onHandleEditBankSubmit}
      requiredMark={false}
      layout='vertical'
      initialValues={{
        bankCode,
        accName,
        cardNumber,
        branch,
        status,
        id
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Bank Type'
          >
            <Input defaultValue={bankTypeList.find(v => v.value === bankType)?.label} disabled placeholder='Remarks' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Bank'
            name='bankCode'
          >
            <Input readOnly disabled defaultValue={bankText} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Remarks'
            name='branch'
            rules={[{ required: true, message: 'Remarks is required.' }]}
          >
            <Input placeholder='Remarks' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label={bankNamePlaceholder}
            name='accName'
            rules={[{ required: true, message: `${bankNamePlaceholder} is required.` }]}
          >
            <Input disabled={isEP} placeholder={bankNamePlaceholder} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Account Status'
            name='status'
          >
            <Select placeholder='Select Status' options={mAccountStatusSelectList} />
          </Form.Item>
        </Col>

        {!isEP &&
          <Col xs={24} sm={8}>
            <Form.Item
              label='Account Number'
              name='cardNumber'
            >
              <Input disabled placeholder='Account Number' />
            </Form.Item>
          </Col>
        }
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postUpdateBankAccountLoading}
        >
          Update
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default EditBankForm
